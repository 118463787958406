import dynamic from 'next/dynamic';

import { breakUpLongTask } from '@/utils/breakUpLongTasks/breakUpLongTasks';

export const componentMap = {
	Accordions: dynamic(breakUpLongTask(() => import('@/components/Accordions'))),
	AgendaStepper: dynamic(breakUpLongTask(() => import('@/components/AgendaStepper'))),
	BirdEyeReviews: dynamic(breakUpLongTask(() => import('@/components/BirdEyeReviews'))),
	Carousel: dynamic(breakUpLongTask(() => import('@/components/Carousel'))),
	CategoryPicker: dynamic(breakUpLongTask(() => import('@/components/CategoryPicker'))),
	Columns: dynamic(breakUpLongTask(() => import('@/components/Columns'))),
	ContentTypeRichText: dynamic(breakUpLongTask(() => import('@/components/ContentTypeRichText'))),
	DisclaimerV2: dynamic(breakUpLongTask(() => import('@/components/Disclaimer'))),
	Footer: dynamic(breakUpLongTask(() => import('@/components/Footer'))),
	GoogleMapTemplate: dynamic(breakUpLongTask(() => import('@/components/GoogleMap'))),
	HalfScreen: dynamic(breakUpLongTask(() => import('@/components/HalfScreen'))),
	Header: dynamic(breakUpLongTask(() => import('@/components/Header'))),
	HeroV2: dynamic(breakUpLongTask(() => import('@/components/Hero'))),
	Iframe: dynamic(breakUpLongTask(() => import('@/components/Iframe'))),
	Insurance: dynamic(breakUpLongTask(() => import('@/components/Insurance'))),
	MeetOurStaff: dynamic(breakUpLongTask(() => import('@/components/MeetOurStaff'))),
	MultiCardV2: dynamic(breakUpLongTask(() => import('@/components/MultiCard'))),
	PatientReviewsV2: dynamic(breakUpLongTask(() => import('@/components/PatientReviews'))),
	QuickLinks: dynamic(breakUpLongTask(() => import('@/components/QuickLinks'))),
	Schema: dynamic(breakUpLongTask(() => import('@/components/Schema'))),
	Service: dynamic(breakUpLongTask(() => import('@/components/Service'))),
	ServiceLineHero: dynamic(breakUpLongTask(() => import('@/components/ServiceLineHero'))),
	SegmentsContainer: dynamic(breakUpLongTask(() => import('@/components/SegmentsContainer'))),
	SingleMessage: dynamic(breakUpLongTask(() => import('@/components/SingleMessage'))),
	SmileAssessmentForm: dynamic(breakUpLongTask(() => import('@/components/SmileAssessmentForm'))),
	Spacer: dynamic(breakUpLongTask(() => import('@/components/Spacer'))),
	Table: dynamic(breakUpLongTask(() => import('@/components/Table'))),
	TermsAndConditions: dynamic(breakUpLongTask(() => import('@/components/TermsAndConditions'))),
	TextBlockSeo: dynamic(breakUpLongTask(() => import('@/components/TextBlockSEO'))),
	LinkForwarder: dynamic(breakUpLongTask(() => import('@/components/LinkForwarder'))),
	OdpHero: dynamic(breakUpLongTask(() => import('@/components/Hero/ODP'))),
	BlogDetailsPage: dynamic(breakUpLongTask(() => import('@/components/BlogDetailsPage'))),
	ProductFeature: dynamic(breakUpLongTask(() => import('@/components/ProductFeature'))),
	BlogListPage: dynamic(breakUpLongTask(() => import('@/components/BlogListPage'))),
	BlogList: dynamic(breakUpLongTask(() => import('@/components/BlogList'))),
	StateLinks: dynamic(breakUpLongTask(() => import('@/components/Centers/StateLinks'))),
	CityLinks: dynamic(breakUpLongTask(() => import('@/components/Centers/CityLinks'))),
	VideoCarousel: dynamic(breakUpLongTask(() => import('@/components/VideoCarousel'))),
	Tabs: dynamic(breakUpLongTask(() => import('@/components/Tabs'))),
	Marquee: dynamic(breakUpLongTask(() => import('@/components/Marquee'))),
	Quiz: dynamic(breakUpLongTask(() => import('@/components/Quiz'))),
};
